let servicesByIconView, servicesByIconColorCircle, serviceSentenceParts

unloadEvents.addEvent(_ => {
    servicesByIconView = null
})

loadEvents.addEvent(_ => {
    servicesByIconView = document.querySelector('#js-servicesByIconView')
    if (servicesByIconView) {
        servicesByIconColorCircle = servicesByIconView.querySelector('#js-servicesByIconColorCircle')
        serviceSentenceParts = servicesByIconView.querySelectorAll('.js-serviceSentencePart')
        serviceSentenceParts.forEach(_serviceSentencePart => {
            _serviceSentencePart.fill = _serviceSentencePart.querySelector('span.fill')
            _serviceSentencePart.content = _serviceSentencePart.fill.querySelector('span')
        })
    }
})

loopEvents.addEvent(function() {
    if (!servicesByIconView) { return }
    const introServicesOffsetY = Math.max(0, smoothScroll.y - (3 * screenSize.height))
    const circleVal = introServicesOffsetY / (screenSize.height * 0.5)
    servicesByIconColorCircle.style.transform = `rotate(${circleVal * 1.5}rad) scale(${1 + 0.3 * Math.sin(circleVal * 2)})`
    const servicesOffsetY = smoothScroll.y - (3.5 * screenSize.height)
    const index = servicesOffsetY / (screenSize.height * 0.25)
    serviceSentenceParts.forEach((_serviceSentencePart, _index) => {
        let p = Math.min(1, index - _index) * 100
        _serviceSentencePart.fill.style.transform = `translateX(${-100 + p}%)`
        _serviceSentencePart.content.style.transform = `translateX(${100 - p}%)`
    })
    const introOpacity = introServicesOffsetY / (screenSize.height * 0.75)
    servicesByIconView.style.opacity = introOpacity <= 1 ? introOpacity : 4.9 - index
}) 