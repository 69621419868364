let fastColumn, slowColumn

unloadEvents.addEvent(_ => {
    fastColumn = null
    slowColumn = null
})

loadEvents.addEvent(_ => {
    fastColumn = document.querySelector('#js-fastColumn')
    slowColumn = document.querySelector('#js-slowColumn')
})

loopEvents.addEvent(_ => {
    if (!fastColumn || !slowColumn) { return }
    const y = (smoothScroll.y - slowColumn.offsetTop + screenSize.height/2) / slowColumn.offsetHeight
    slowColumn.style.transform = `translateY(${y * 240}px)`
    fastColumn.style.transform = `translateY(${y * -320}px)`
})