const swup = new Swup({
    containers: ["#js-contentBox"],
    plugins: [new SwupBodyClassPlugin()]
})

swup.on('samePage', closeMenu)

swup.on('pageView', _ => {
    _paq.push(['setCustomUrl', window.location.href])
    _paq.push(['trackPageView', document.title])
})

swup.on('contentReplaced', _ => {
    loadEvents.play()
    resizeEvents.play()
    loadDidEndEvents.play()
})

swup.on('transitionStart', _ => unloadEvents.play())

loadDidEndEvents.addEvent(_ => {
    document.documentElement.classList.remove('is-animating')
})

loadEvents.play()
firstLaunchEvents.play()
window.addEventListener('load', () => resizeEvents.play() )

if (document.readyState == "complete") {
    loadDidEndEvents.play()
}else{
    window.addEventListener('load', _ => { loadDidEndEvents.play() }, false);
}