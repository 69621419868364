let brandingDisplayBoxes

unloadEvents.addEvent(_ => {
    brandingDisplayBoxes = []
})

loadEvents.addEvent(_ => {
    brandingDisplayBoxes = document.querySelectorAll('.brandDisplayBox')
})

loopEvents.addEvent(_ => {
    if (brandingDisplayBoxes.length == 0) { return }
    brandingDisplayBoxes.forEach( (box, boxIndex) => {
        const offsetTop = box.offsetTop
        const prog = (smoothScroll.y - offsetTop) / screenSize.height
        if (prog <= -1 || prog >= 1) { return }
        const offset = boxIndex < 2 ? 0.475 : 0.7
        for (let i = 0; i < 3; i++) {
            ( i * 0.15 < (prog + offset)) ? box.classList.add(`step-${i}`) : box.classList.remove(`step-${i}`)
        }
        for (const child of box.children) {
            if (child.classList.contains('name')) {
                child.style.transform = `translateY(${prog * -15}vh)`
            } else if (child.classList.contains('logo')) {
                child.style.transform = `translateY(${prog * 24}vh)`
            } else if(child.classList.contains('previewPicture')) {
                child.lastElementChild.style.transform = `scale(${1 + prog * 0.075})`
            }
        }
    })
})