let digitalComputerScreen

unloadEvents.addEvent(_ => {
    digitalComputerScreen = null
})

loadEvents.addEvent(_ => {
    digitalComputerScreen = document.querySelector('#js-computerScreen')
})

loopEvents.addEvent(_ => {
    if (!digitalComputerScreen) { return }
    digitalComputerScreen.style.transform = `
        translate(${pointer.smoothRelativeX * -100}px, ${pointer.smoothRelativeY * -100}px)
        rotate(${pointer.smoothRelativeX * 20}deg)`
})