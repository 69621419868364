let digitalGaxSiteVideo

unloadEvents.addEvent(_ => {
    digitalGaxSiteVideo = null
})

loadEvents.addEvent(_ => {
    digitalGaxSiteVideo = document.querySelector('#js-gaxSiteVideo')
})

loopEvents.addEvent(_ => {
    if (!digitalGaxSiteVideo) { return }
    const prog = ((smoothScroll.y - digitalGaxSiteVideo.parentElement.offsetTop) / screenSize.height) + 0.5
    const time = digitalGaxSiteVideo.duration * prog
    if (isFinite(time) && digitalGaxSiteVideo.currentTime != time ) {
        digitalGaxSiteVideo.currentTime = time
    }
})