class EventsArray {

    constructor(events = []) {
        this.events = events
    }

    addEvent(event) {
        this.events.push(event)
    }

    play(e = null) {
        this.events.forEach(event => event(e))
    }

}