let animatedLogoContainer

loadEvents.addEvent(_ => {
    animatedLogoContainer = document.querySelector('#js-animatedLogo')
    lottie.loadAnimation({
        container: animatedLogoContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/assets/lottie/branding_animated_logo.json'
      })
})