const screenSize = { width: 0, height: 0, centerX: 0, centerY: 0}
const resizeEvents = new EventsArray()

resizeEvents.addEvent(function() {
    screenSize.width = window.innerWidth
    screenSize.height = window.innerHeight
    screenSize.centerX = screenSize.width / 2
    screenSize.centerY = screenSize.height / 2
})

window.addEventListener('resize', _ => resizeEvents.play())

firstLaunchEvents.addEvent(_ => resizeEvents.play())