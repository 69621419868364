let parallaxImages

unloadEvents.addEvent(_ => {
    parallaxImages = []
})

loadEvents.addEvent(_ => {
    parallaxImages = document.querySelectorAll('#js-imaginaryParallaxBackground img')
})

loopEvents.addEvent(_ => {
    if (parallaxImages.length == 0) { return }
    parallaxImages.forEach((img, i) => {
        const speedRatio = (i - 4) * -0.2
        img.style.transform = `translateY(${smoothScroll.y * speedRatio}px)`
    })
})