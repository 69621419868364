let heartBeatBoxes

unloadEvents.addEvent(_ => {
    heartBeatBoxes = []
})

loadEvents.addEvent(_ => {
    heartBeatBoxes = document.querySelectorAll('.js-heartBeatBox')
    if (heartBeatBoxes.length > 0) {
        heartBeatBoxes.forEach(box => {
            box.liveChild = box.querySelector('.js-live')
        })
    }
})

loopEvents.addEvent(_ => {
    if (heartBeatBoxes.length == 0) { return }
    const now = new Date()
    heartBeatBoxes.forEach(box => {
        box.liveChild.style.opacity = Math.abs(Math.sin(now * 0.001))
    })
})