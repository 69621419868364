let digitalIntroduction, digitalOffers, digitalSky

unloadEvents.addEvent(_ => {
    digitalIntroduction = null
})

loadEvents.addEvent(_ => {
    digitalIntroduction = document.querySelector('#js-digitalIntroduction')
    if (digitalIntroduction) {
        digitalOffers = digitalIntroduction.querySelectorAll('.js-offer')
        digitalSky = digitalIntroduction.querySelector('#js-digitalSky')
        digitalOffers.forEach( (offer, index) => {
            offer.addEventListener('mouseover', _ => {
                for (let i = 0; i < 3; i++) {
                    if (i == index) {
                        digitalSky.classList.add(`skyLayer-${i}`)       
                    } else {
                        digitalSky.classList.remove(`skyLayer-${i}`)
                    }
                }
            })
        })
    }
})

resizeEvents.addEvent(_ => {
    if (!digitalIntroduction) { return }
    digitalSky.querySelectorAll('img').forEach( (img, index) => {
        let x = (index % 2 == 0 ? 0.7 + (Math.random() * 0.3) : Math.random() * 0.3) - 0.5
        img.style.left = `${-8 + (x * screenSize.width)}px`
        img.style.top = `${-8 + (Math.random() - 0.5) * screenSize.height}px`
    })
})