let whoWeAre, neons, parallaxBoxes, slides, backgroundSlides, sliderPoints

unloadEvents.addEvent(_ => {
    whoWeAre = null
})

loadEvents.addEvent(_ => {
    whoWeAre = document.querySelector('#js-whoWeAre')
    if (whoWeAre) {
        neons = whoWeAre.querySelectorAll('.light')
        parallaxBoxes = whoWeAre.querySelectorAll('.parallaxBox')
        slides = whoWeAre.querySelector('#js-slides')
        backgroundSlides = whoWeAre.querySelectorAll('#js-backgroundSlides img')
        sliderPoints = whoWeAre.querySelectorAll('#js-sliderPoints .point')
        slideTo(0)
        sliderPoints.forEach( (point, i) => {
            point.addEventListener('click', _ => slideTo(i) )
        })
        if (document.body.classList.contains('about')) {
            setTimeout(_ => {
                window.scrollTo(0, whoWeAre.offsetTop - (screenSize.height * 1/3))
                smoothScrollResizeAction()
            }, 50)
        }
    }
})

function slideTo(i) {
    slides.style.transform = `translateX(${i * -20}%)`
    backgroundSlides.forEach((slide, index) => {
        if (i >= index) {
            slide.classList.add('current')
        } else {
            slide.classList.remove('current')
        }
    })
    sliderPoints.forEach((point, index) => {
        if (i == index) {
            point.classList.add('current')
        } else {
            point.classList.remove('current')
        }
    })
}

resizeEvents.addEvent(function() {
    if (!whoWeAre) { return }
    neons.forEach(neon => {
        neon.positionY = neon.offsetTop + neon.parentElement.parentElement.offsetTop
    })
    parallaxBoxes.forEach(box => {
        if (box.getAttribute('data-depth') == '1') {
            box.positionY = box.offsetTop + box.parentElement.offsetTop + box.parentElement.parentElement.offsetTop
        } else {
            box.positionY = box.offsetTop + box.parentElement.offsetTop
        }
        box.image = box.querySelector('img')
        if (box.lastMouseCentering == null) {
            box.lastMouseCentering = 0
        }
    })
})
loopEvents.addEvent(function() {
    if (!whoWeAre) { return }
    const offsetX = (smoothScroll.y - 10.5 * screenSize.height) / screenSize.height
    if (offsetX < -0.5) { return }
    neons.forEach(neon => {
        const remaining = Math.max(0, .75 + (neon.positionY - smoothScroll.y) / -screenSize.height)
        neon.style.transform = `scaleX(${remaining})`
    })
    parallaxBoxes.forEach(box => {
        const remaining = 0.1 + (box.positionY - smoothScroll.y) / -screenSize.height
        box.style.transform  = `translateY(${remaining * -33}%)`
        const targetMouseCentering = Math.abs(pointer.x / screenSize.width - 0.5) + Math.abs(pointer.y / screenSize.height - 0.5)
        box.lastMouseCentering = box.lastMouseCentering + (targetMouseCentering - box.lastMouseCentering) * 0.04
        box.image.style.transform = `translateY(${-1 + box.lastMouseCentering}px)`
    })
})