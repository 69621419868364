let startButtons

loadEvents.addEvent(_ => {
    startButtons = document.querySelectorAll('.startButton')
})

function onMouseMoveStartButtons() {
    startButtons.forEach(button => {
        const onScreenY = button.data.y - smoothScroll.y
        const deltaY = (onScreenY - pointer.y)
        const deltaX = (pointer.x - button.data.x)
        if (Math.abs(deltaY) + Math.abs(deltaX) > button.data.width + button.data.height) {
            return
        }
        const relativeDeltaX = deltaX / button.data.width
        if (relativeDeltaX > 0.75 || relativeDeltaX < -0.75) {
            return
        }
        button.pointer.style.transform = `
            translateX(${deltaX}px)
            translateY(${8 - 16 * Math.abs(relativeDeltaX)}px)
            rotate(${relativeDeltaX * -80}deg)`
    })
}
pointerEvents.addEvent(onMouseMoveStartButtons)

function onResizeStartButtons() {
    startButtons.forEach(button => {
        const rect = button.getBoundingClientRect()
        button.data = {
            x: rect.left + (rect.width / 2),
            y: window.scrollY + rect.top + (rect.height / 2),
            width : rect.width,
            height: rect.height
        }
        button.pointer = button.querySelector('.mousePointer')
    })
}
resizeEvents.addEvent(onResizeStartButtons)
