let digitalHowWeDoSection, digitalSpecifications, digitalSpecificationsLetters, digitalSpecificationsLettersCount, digitalSteps, digitalHowWeDoPlayground, digitalBlocks, digitalBlocksCount, digitalProgressBar

unloadEvents.addEvent(_ => {
    digitalHowWeDoSection = null
})

loadEvents.addEvent(_ => {
    digitalHowWeDoSection = document.querySelector('#js-digitalHowWeDoSection')
    if(digitalHowWeDoSection) {
        digitalSpecifications = digitalHowWeDoSection.querySelector('#js-digitalSpecifications')
        digitalSpecificationsLetters = Array.from(digitalSpecifications.querySelectorAll('.textLine'))
                                            .flatMap(line => {
                                                const lineCharacters = Array.from(line.innerText)
                                                line.innerHTML = ''
                                                const characters = lineCharacters
                                                                    .map(character => {
                                                                        const span = document.createElement('span')
                                                                        span.innerText = character
                                                                        line.appendChild(span)
                                                                        return span
                                                                    })
                                                return characters
                                            })
        digitalSpecificationsLettersCount = digitalSpecificationsLetters.length
        digitalSteps = digitalHowWeDoSection.querySelectorAll('.step')
        digitalHowWeDoPlayground = digitalHowWeDoSection.querySelector('#js-digitalHowWeDoPlayground')
        digitalBlocks = digitalHowWeDoPlayground.querySelectorAll('.block')
        digitalBlocksCount = digitalBlocks.length
        digitalProgressBar = document.querySelector('#js-digitalProgressBar')
    }
})

resizeEvents.addEvent(_ => {
    if(!digitalHowWeDoSection) { return }
    digitalSteps.forEach(step => {
        step.realOffsetTop = step.offsetTop + step.parentElement.offsetTop
    })
})

loopEvents.addEvent(_ => {
    if(!digitalHowWeDoSection) { return }
    const progress = (smoothScroll.y - digitalSteps[0].realOffsetTop) / screenSize.height
    if (progress < -1.5) { return }
    const stepIndex = progress + 0.5
    digitalProgressBar.style.transform = `scaleY(${Math.min(5/6, Math.max(0, 1/12 + progress / 6))})`
    digitalSteps.forEach((step, i) => {
        if (i <= stepIndex) {
            step.classList.add('active')
        } else {
            step.classList.remove('active')
        }
    })
    if (progress <= 0) {
        const specificationsProgress = Math.round((progress + 0.5) * digitalSpecificationsLettersCount * 1.5)
        digitalSpecificationsLetters.forEach( (letter, i) => {
            if (i <= specificationsProgress) {
                letter.classList.add('visible')
            } else {
                letter.classList.remove('visible')
            }
        })
        digitalSpecifications.classList.remove('hidden')
    } else {
        digitalSpecifications.classList.add('hidden')
    }
    digitalBlocks.forEach( (block, i) => {
        const relativProg = progress - (i / digitalBlocksCount)
        if (relativProg > 4) {
            block.classList.add('zoning', 'wireframe', 'ui', 'dev', 'production')
        } else if (relativProg > 3) {
            block.classList.add('zoning', 'wireframe', 'ui', 'dev')
            block.classList.remove('production')
        } else if (relativProg > 2) {
            block.classList.add('zoning', 'wireframe', 'ui')
            block.classList.remove('dev', 'production')
        } else if (relativProg > 1) {
            block.classList.add('zoning', 'wireframe')
            block.classList.remove('ui', 'dev', 'production')
        } else if (relativProg > 0) {
            block.classList.add('zoning')
            block.classList.remove('wireframe', 'ui', 'dev', 'production')
        } else {
            block.classList.remove('zoning', 'wireframe', 'ui', 'dev', 'production')
        }
    })
})