let scrollBanner, scrollBannerTop

unloadEvents.addEvent(_ => {
    scrollBanner = null
})

loadEvents.addEvent(_ => {
    scrollBanner = document.querySelector('#js-scrollBanner')
    if (scrollBanner) {
        scrollBannerTop = scrollBanner.parentElement.offsetTop
    }
})

resizeEvents.addEvent(_ => {
    if (!scrollBanner) { return }
    scrollBannerTop = scrollBanner.parentElement.offsetTop
})

loopEvents.addEvent(_ => {
    if (!scrollBanner) { return }
    const offsetX = (smoothScroll.y - scrollBannerTop + screenSize.height) / screenSize.height
    if (offsetX < -0.5) { return }
    scrollBanner.style.transform = `translateX(${-offsetX * 2}%)`
})   