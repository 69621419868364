let inspirationWaitingBackground

unloadEvents.addEvent(_ => {
    inspirationWaitingBackground = null
})

loadEvents.addEvent(_ => {
    inspirationWaitingBackground = document.querySelector('#js-inspirationWaitingBackground')
})

resizeEvents.addEvent(_ => {
    if (inspirationWaitingBackground) {
        inspirationWaitingBackground.start = inspirationWaitingBackground.parentElement.offsetTop - (screenSize.height / 2)
        inspirationWaitingBackground.end = inspirationWaitingBackground.start + inspirationWaitingBackground.parentElement.children[1].offsetHeight + inspirationWaitingBackground.parentElement.children[2].children[0].offsetHeight + inspirationWaitingBackground.parentElement.children[2].children[1].offsetHeight
    }
})

loopEvents.addEvent(_ => {
    if (!inspirationWaitingBackground) { return }
    if (smoothScroll.y > inspirationWaitingBackground.start && smoothScroll.y < inspirationWaitingBackground.end) {
        inspirationWaitingBackground.classList.add('visible')
    } else {
        inspirationWaitingBackground.classList.remove('visible')
    }
})