let contactForm, contactFieldBoxes

unloadEvents.addEvent(_ => {
    if (contactForm) {
        contactForm.removeEventListener('submit', contactFormSubmitAction)   
    }
    contactForm = null
})

loadEvents.addEvent(_ => {
    contactForm = document.querySelector('#js-contactForm')
    if (contactForm) {
        contactFieldBoxes = contactForm.querySelectorAll('.inputBox')
        contactFieldBoxes.forEach(box => {
            let input = box.querySelector('.inputfield')
            input.addEventListener('input', _ => {
                box.classList.remove('withError')
            })
        })
        contactForm.addEventListener('submit', contactFormSubmitAction)   
    }
})

const contactFormSubmitAction = e => {
    e.preventDefault()
    const data = new FormData(contactForm)
    let error = false
    if (!data.get('lastname')) {
        contactFieldBoxes[0].classList.add('withError')
        error = true
    }
    if (!data.get('firstname')) {
        contactFieldBoxes[1].classList.add('withError')
        error = true
    }
    if (!data.get('email')) {
        contactFieldBoxes[2].classList.add('withError')
        error = true
    }
    if (!data.get('topic')) {
        contactFieldBoxes[3].classList.add('withError')
        error = true
    }
    if (!data.get('message')) {
        contactFieldBoxes[4].classList.add('withError')
        error = true
    }
    if (error) { return }
    fetch('/php/actions/sendContact.php', {
        method: 'post',
        body: data
    })
        .then(resp => resp.text())
        .then(text => {
            if (text.includes("mail sent")) {
                _paq.push(['trackEvent', 'contact', 'sent', data.get('email')]);
                const successView = document.querySelector('#js-successView')
                successView.classList.add('visible')
                console.log(text)
            } else {
                console.warn(`Erreur d'envoie du mail : ${text}`)
            }
        })
}