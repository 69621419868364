let brandingDecorations

unloadEvents.addEvent(_ => {
    brandingDecorations = []
})

loadEvents.addEvent(_ => {
    brandingDecorations = document.querySelectorAll('span.decoration')
    brandingDecorations.forEach(deco => {
        deco.skew = deco.getAttribute('data-skew') ?? 12
    })
})

loopEvents.addEvent(_ => {
    if (brandingDecorations.length == 0) { return }
    brandingDecorations.forEach( deco => {
        const prog = (smoothScroll.y - deco.offsetTop) / screenSize.height
        if (prog < -1.2 || prog > 1) { return }
        deco.style.transform = `translateX(${ (prog + 0.75) * -20}%) skew(-${deco.skew}deg)`
    })
})