let gaxColors, gaxColorsX, gaxColorsY, gaxColorsAngle

unloadEvents.addEvent(_ => {
    gaxColors = null
})

loadEvents.addEvent(_ => {
    gaxColors = document.querySelector('#js-gaxColors')
    gaxColorsX = 0
    gaxColorsY = 0
    gaxColorsAngle = 0
})

loopEvents.addEvent(function() {
    if (!gaxColors) { return }
    if (smoothScroll.y > screenSize.height * 1.5) { return }
    const t = (Math.sin(Date.now() * 0.0005) + 1) * 10
    const gaxColorsScale = (1 + t * 0.02) + (smoothScroll.y / screenSize.height * 2)
    const deltaX = (pointer.x - screenSize.centerX)
    gaxColorsX = gaxColorsX + (deltaX - gaxColorsX) * 0.01
    const deltaY = (pointer.y - screenSize.centerY)
    gaxColorsY = gaxColorsY + (deltaY - gaxColorsY) * 0.01
    const targetAngle = Math.atan2(pointer.y - screenSize.centerY, pointer.x - screenSize.centerX) + Math.PI
    gaxColorsAngle = gaxColorsAngle + (targetAngle - gaxColorsAngle) * 0.001
    const disappear = 1 - (smoothScroll.y / screenSize.height)
    gaxColors.style.transform = `
        scale(${gaxColorsScale})
        translateX(${gaxColorsX / -6}px)
        translateY(${gaxColorsY / -6}px)
        rotate(${-.75 + gaxColorsAngle / 2.5 * disappear}rad)`
    gaxColors.style.opacity = disappear
})