const header = document.querySelector('#js-header')
const menuButton = document.querySelector('#js-menuButton')
const menuLinksWithSublinks = document.querySelectorAll('.js-menuLinkWithSublinks')

function closeMenu() {
    document.body.classList.remove('menuOpen')
    menuLinksWithSublinks.forEach( _menuLinkWithSublinks => {
        _menuLinkWithSublinks.classList.remove('open')
    })
}

unloadEvents.addEvent(_ => {
    closeMenu()
})

menuLinksWithSublinks.forEach(_menuLinkWithSublinks => {
    _menuLinkWithSublinks.addEventListener('click', e => {
        if (e.target instanceof HTMLAnchorElement) {
            return
        }
        _menuLinkWithSublinks.classList.toggle('open')
    })
})

menuButton.addEventListener('click', e => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.toggle('menuOpen')
})

scrollEvents.addEvent(function() {
    closeMenu()
    if (window.scrollY > 0) {
        header.classList.add('miniaturized')
    } else {
        header.classList.remove('miniaturized')
    }
})