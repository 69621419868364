let brandingDetails, brandingDetailIcons

unloadEvents.addEvent(_ => {
    brandingDetails = null
})

loadEvents.addEvent(_ => {
    brandingDetails = document.querySelector('#js-brandingDetails')
    if (brandingDetails) {
        brandingDetailIcons = brandingDetails.querySelectorAll('.js-detailIcon')
    }
})

resizeEvents.addEvent(_ => {
    if (!brandingDetails) { return }
    brandingDetails.positionY = brandingDetails.offsetTop
})

loopEvents.addEvent(_ => {
    if (!brandingDetails) { return }
    const scroll = (smoothScroll.y - brandingDetails.positionY + screenSize.height) / screenSize.height
    brandingDetailIcons.forEach( (icon, i) => {
        const rotate = 30 - (scroll - (i * 0.15)) * 60
        icon.style.transform = `rotateY(${rotate}deg)`
    })
})