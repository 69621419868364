let brandingLandingPage

unloadEvents.addEvent(_ => {
    brandingLandingPage = null
})

loadEvents.addEvent(_ => {
    brandingLandingPage = document.querySelector('#js-landingPage')
})

loopEvents.addEvent(_ => {
    if (!brandingLandingPage) { return }
    const realOffsetCenter = brandingLandingPage.parentElement.offsetTop + (brandingLandingPage.offsetHeight / 2)
    const prog = (((smoothScroll.y + screenSize.height / 2) - realOffsetCenter) / screenSize.height)
    brandingLandingPage.style.transform = `scale(${1 + Math.abs(prog * 0.05)})`
})