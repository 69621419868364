let contentBox = document.querySelector('#js-contentBox')
let staticSections = document.querySelectorAll('.js-static')
let isPointerCoarse = false
const smoothScroll = {
    y: 0,
    totalHeight: null,
    nearestMagnet: null,
    lastUpdate: 0,
    factor: 0.06,
    lastDelta: 0,
    frameRateFactor: now => ( Math.min(5, (now - smoothScroll.lastUpdate) / (1000/60)) )
}

unloadEvents.addEvent(_ => {
    window.scrollTo(0, 0)
    smoothScroll.y = 0
})

loadEvents.addEvent(_ => {
    isPointerCoarse = matchMedia('(pointer:coarse)').matches
    contentBox = document.querySelector('#js-contentBox')
    staticSections = document.querySelectorAll('.js-static')
})

function updateCurrentY(value) {
    smoothScroll.y = value
    staticSections.forEach(section => {
        section.style.transform = `translateY(${smoothScroll.y}px)`
    })
    contentBox.style.transform = `translateY(${-smoothScroll.y}px)` 
}

function smoothScrollResizeAction(_withScrollUpdate = true) {
    document.body.style.height = `${contentBox.offsetHeight}px`
    smoothScroll.totalHeight = contentBox.offsetHeight - screenSize.height
    if (_withScrollUpdate) {
        updateCurrentY(window.scrollY)   
    }
}

// Observe div resize
if (typeof ResizeObserver != "undefined") {
    const resize_observer = new ResizeObserver(smoothScrollResizeAction);
    resize_observer.observe(contentBox);
}

// Add to classic resize events
resizeEvents.addEvent(e => {
    smoothScrollResizeAction(e ?? true)
})

loopEvents.addEvent(_ => {
    const now = new Date()
    const windowY = window.scrollY
    const scrollDelta = (windowY - smoothScroll.y)
    const deltaIsGrowing = Math.abs(scrollDelta) > Math.abs(smoothScroll.lastDelta)
    if (scrollDelta != 0) {
        if (windowY / smoothScroll.totalHeight >= 1) {
            resizeEvents.play(false)
        }
        if (!isPointerCoarse && smoothScroll.nearestMagnet != null && Math.abs(scrollDelta) < 100 && !deltaIsGrowing) {
            window.scrollTo(0, smoothScroll.nearestMagnet - 80)
            smoothScroll.nearestMagnet = null
        }
        if (isPointerCoarse) {
            updateCurrentY(windowY)
        } else if (scrollDelta < 1 && scrollDelta > -1) {
            updateCurrentY(windowY)
        } else {
            updateCurrentY(smoothScroll.y + (windowY - smoothScroll.y) * smoothScroll.factor * smoothScroll.frameRateFactor(now))
        }
    }
    smoothScroll.lastUpdate = now
    smoothScroll.lastDelta = scrollDelta
})