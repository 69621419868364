const pointer = { x: 0, y: 0, relativeX: 0, relativeY: 0, smoothRelativeX: 0, smoothRelativeY: 0}
const pointerEvents = new EventsArray()

pointerEvents.addEvent(function(e) {
    pointer.x = e.clientX
    pointer.y = e.clientY
    pointer.relativeX = (pointer.x / screenSize.width) - 0.5
    pointer.relativeY = (pointer.y / screenSize.height) - 0.5
})

window.addEventListener('mousemove', e => pointerEvents.play(e))

loopEvents.addEvent(_ => {
    pointer.smoothRelativeX = pointer.smoothRelativeX + (pointer.relativeX - pointer.smoothRelativeX) * 0.01
    pointer.smoothRelativeY = pointer.smoothRelativeY + (pointer.relativeY - pointer.smoothRelativeY) * 0.01
})