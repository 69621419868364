const mainServices = {
    isMobile: false,
    limit: 800,
    elements: [],
    mobile: [
        '<video autoplay autobuffer muted playsinline loop><source src="/assets/videos/branding-portrait-opti.mp4" type="video/mp4"></video>',
        '<video autoplay autobuffer muted playsinline loop><source src="/assets/videos/imaginary-portrait-opti.mp4" type="video/mp4"></video>',
        '<video autoplay autobuffer muted playsinline loop><source src="/assets/videos/digital-portrait-opti.mp4" type="video/mp4"></video>',
    ],
    desktop: [
        "<iframe src='https://my.spline.design/brandingok-8715ed04fd567935b7f1793904fabf77/' frameborder='0'></iframe>",
        "<iframe src='https://my.spline.design/imaginarycopy-ea4f2c2779a42720e3607402da6076fa/' frameborder='0'></iframe>",
        "<iframe src='https://my.spline.design/digitalllcopy-8c2fec1e559a365cda1d78250392ef3a/' frameborder='0'></iframe>",
    ]
}

const homeMainServicesSetContent = _ => {
    if (mainServices.elements.length == 3) {
        mainServices.isMobile = screenSize.width < mainServices.limit
        const key = mainServices.isMobile ? "mobile" : "desktop"
        mainServices.elements.forEach( (elem, i) => {
            elem.innerHTML = mainServices[key][i]
            elem.video = elem.querySelector('video') ?? null
        })
    }
}

Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function(){
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
})

window.addEventListener('touchstart', _ => {
    if (!mainServices.isMobile) return
    mainServices.elements.forEach(_elem => {
        if (!_elem.video) return
        if (_elem.video.playing) return
        _elem.video.play()
    })
})

const homeMainServicesDidLoad = _ => {
    mainServices.elements = document.querySelectorAll('.js-mainServiceBackground') ?? []
    homeMainServicesSetContent()
}

const homeMainServicesDidResize = _ => {
    if (mainServices.isMobile == screenSize.width < mainServices.limit) return
    homeMainServicesSetContent()
}

resizeEvents.addEvent(homeMainServicesDidResize)

loadDidEndEvents.addEvent(homeMainServicesDidLoad)