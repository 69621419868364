let brandingDaughterBrands, brandingBrandsBackgroundStack
let brandingHoveredDaughterLogo = null, brandingX = 0

unloadEvents.addEvent(_ => {
    brandingDaughterBrands = null
    brandingHoveredDaughterLogo = null
})

loadEvents.addEvent(_ => {
    brandingDaughterBrands = document.querySelector('#js-daughterBrands')
    if (brandingDaughterBrands) {
        brandingBrandsBackgroundStack = brandingDaughterBrands.querySelector('#js-backgroundStack')
        let brandsLogo =  brandingDaughterBrands.querySelectorAll('.brand')
        brandsLogo.forEach( (logo, i) => {
            logo.addEventListener('mouseenter', _ => {
                brandingHoveredDaughterLogo = i
            })
            logo.addEventListener('mouseleave', _ => {
                brandingHoveredDaughterLogo = null
            })
        })
    }
})

loopEvents.addEvent(_ => {
    if (!brandingDaughterBrands) { return }
    brandingX = brandingX + (pointer.relativeX  * 100 - brandingX) * smoothScroll.factor
    brandingBrandsBackgroundStack.style.transform = `translateX(${brandingX}vw)`
    brandingBrandsBackgroundStack.classList.remove('focus-0', 'focus-1', 'focus-2', 'focus-3')
    if (brandingHoveredDaughterLogo != null) {
        brandingBrandsBackgroundStack.classList.add(`focus-${brandingHoveredDaughterLogo}`)
    }
})