const factors = [
    {startX: -0.2,startY: 0.9, x: -0.3, y: 0.7},
    {startX: 0.2,startY: 1, x: 0, y: 1.5},
    {startX: 0.1,startY: 0.3, x: 0.1, y: 0.6},
    {startX: 0.7,startY: 0.7, x: -0.2, y: 1.7},
    {startX: 0.25,startY: 1.5, x: 0, y: 0.1},
]
let digitalBackgroundBubbles

unloadEvents.addEvent(_ => {
    digitalBackgroundBubbles = null
})

loadEvents.addEvent(_ => {
    digitalBackgroundBubbles = document.querySelectorAll('#js-digitalBackground .bubble')
})

loopEvents.addEvent(_ => {
    if (!digitalBackgroundBubbles) { return }
    digitalBackgroundBubbles.forEach( (bubble, i) => {
        const pointerX = pointer.smoothRelativeX * screenSize.width / 2
        const pointerY = pointer.smoothRelativeY * screenSize.height / 2
        const x = (screenSize.width * factors[i].startX) - (smoothScroll.y + pointerX) * factors[i].x
        const y = (screenSize.height * factors[i].startY) - (smoothScroll.y/2  + pointerY) * factors[i].y
        bubble.style.transform = `translate(${x}px, ${y}px)`
    })
})