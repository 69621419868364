let welcomeToGaxFilter, welcomeToGaxWeCreate, welcomeToGaxText

unloadEvents.addEvent(_ => {
    welcomeToGaxFilter = null
})

loadEvents.addEvent(_ => {
    welcomeToGaxFilter = document.querySelector('#js-gaxFilter')
    if (welcomeToGaxFilter) {
        welcomeToGaxWeCreate = document.querySelector('#js-weCreate') 
        welcomeToGaxText = document.querySelector('#js-gaxWelcomeText')
    }
})

loopEvents.addEvent(function() {
    if (!welcomeToGaxFilter) { return }
    const welcomeToGaxOffsetY = Math.max(0, smoothScroll.y - (screenSize.height))
    if (welcomeToGaxOffsetY > screenSize.height * 2) { return }
    const size = 1 + easings.easeInQuad(welcomeToGaxOffsetY / screenSize.height) * 30
    welcomeToGaxFilter.style.transform = `translateY(${welcomeToGaxOffsetY}px) scale(${size})`
    welcomeToGaxText.style.opacity = 3 - size
    const yProgression = easings.easeOutQuad(Math.min(1, Math.max(0, (welcomeToGaxOffsetY - screenSize.height)  / screenSize.height)))
    welcomeToGaxWeCreate.style.transform = `translateY(${screenSize.height - yProgression * screenSize.height}px)`
})  