let zoomOnScrollViews

unloadEvents.addEvent(_ => {
    zoomOnScrollViews = []
})

loadEvents.addEvent(_ => {
    zoomOnScrollViews = document.querySelectorAll('.js-zoomOnScroll')
})

resizeEvents.addEvent(_ => {
    if (zoomOnScrollViews.length == 0) { return }
    zoomOnScrollViews.forEach( (view, i) => {
        if (i == 0) {
            view.realOffsetTop = view.parentElement.parentElement.parentElement.offsetTop
        } else if (view.classList.contains('display')) {
            view.realOffsetTop = view.offsetTop
        } else {
            view.realOffsetTop = view.parentElement.parentElement.offsetTop + view.parentElement.parentElement.parentElement.offsetTop
        }
    })
})
loopEvents.addEvent(_ => {
    if (zoomOnScrollViews.length == 0) { return }
    zoomOnScrollViews.forEach( (view, i) => {
        const prog = (smoothScroll.y - view.realOffsetTop) / screenSize.height
        if (prog < -1.2 || prog > 1) { return }
        view.style.transform = (i % 2 != 0) ? `scale(${1.2 + prog * 0.2})` : `scale(${1.2 - prog * 0.2})`
    })
})