let mainServicesView, mainServicesBackgrounds, immersivePortfolio, immersivePortfolioContent

unloadEvents.addEvent(_ => {
    mainServicesView = null
})

loadEvents.addEvent(_ => {
    mainServicesView = document.querySelector('#js-mainServicesView')
    if (mainServicesView) {
        mainServicesBackgrounds = mainServicesView.querySelectorAll('.background')
        immersivePortfolio = document.querySelector('#js-immersivePortfolio')
        immersivePortfolioContent = immersivePortfolio.querySelector('.content')
    }
})

loopEvents.addEvent(function() {
    if (!mainServicesView) { return }
    const mainServicesScroll = (smoothScroll.y - screenSize.height * 4.75) / (screenSize.height * 1.5)
    if (mainServicesScroll < -1 || mainServicesScroll > 5) { return }
    const index = Math.min(4, Math.ceil(mainServicesScroll))
    mainServicesView.classList.remove(`step-${index - 1}`)
    mainServicesView.classList.add(`step-${index}`)
    mainServicesView.classList.remove(`step-${index + 1}`)
    mainServicesBackgrounds.forEach( (bg, i) => {
        bg.style.transform = `translateY(${80 + ((mainServicesScroll - i) * -160)}px)`
    })
    immersivePortfolio.style.opacity = (mainServicesScroll - 3) * 2
    immersivePortfolioContent.style.opacity = (mainServicesScroll - 3.25) * 4
    immersivePortfolioContent.style.transform = `translateY(${Math.max(0, ((mainServicesScroll - 3.5) * 4) * -20)}%)`
})