let brandingHero, brandingHeroLogoPath, brandingHeroLogoCircle, brandingHeroBottomWave

unloadEvents.addEvent(_ => {
    brandingHero = null
})

loadEvents.addEvent(_ => {
    brandingHero = document.querySelector('#js-brandingHero')
    if (brandingHero) {
        brandingHeroLogoPath = brandingHero.querySelector('#js-heroLogoPath')
        brandingHeroLogoCircle = brandingHero.querySelector('#js-heroLogoCircle')
        brandingHeroBottomWave = brandingHero.querySelector('#js-heroBottomWave')
    }
})

loopEvents.addEvent(_ => {
    if (!brandingHero) { return }
    const prog = smoothScroll.y / screenSize.height
    if (prog <= 1) {
        const x = pointer.smoothRelativeX * 50 + prog * -200
        const y = pointer.smoothRelativeY * 50 + prog * -200
        brandingHeroLogoPath.style.transform = `translate(${x}%, ${y}%)`
        brandingHeroLogoCircle.style.transform = `translate(${x * -1}%, ${y * -1}%)`
        brandingHeroBottomWave.style.transform = `scaleY(${1.5 - prog})`
    }
})