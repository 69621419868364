let projectsSliderIndex, projectsBox, projectsRightArrow, projectsLeftArrow, projectsContainer, projectsSliderElements, projectsSliderCount, projectsSliderControlPoint

unloadEvents.addEvent(_ => {
    if (projectsBox) {
        projectsRightArrow.removeEventListener('click', projectsRightArrowAction)
        projectsLeftArrow.removeEventListener('click', projectsLeftArrowAction)
    }
    projectsBox = null
})

loadEvents.addEvent(_ => {
    projectsSliderIndex = 0
    projectsBox = document.querySelector('#js-projectsBox')
    if (projectsBox) {
        projectsRightArrow = projectsBox.querySelector('#js-projectsRightArrow')
        projectsLeftArrow = projectsBox.querySelector('#js-projectsLeftArrow')
        projectsContainer = projectsBox.querySelector('#js-projectsContainer')
        projectsSliderElements = projectsContainer.querySelectorAll('.project')
        projectsSliderCount = projectsSliderElements.length
        projectsSliderControlPoint = document.querySelector('#js-controlPoint')
        projectsRightArrow.addEventListener('click', projectsRightArrowAction)
        projectsLeftArrow.addEventListener('click', projectsLeftArrowAction)
        updateProjectsSliderDisplay()
    }
})

const projectsRightArrowAction = _ => {
    const elementsByScreen = screenSize.width <= 560 ? 1 : 2
    if (projectsSliderIndex + elementsByScreen < projectsSliderCount) {
        projectsSliderIndex++   
    } else {
        projectsSliderIndex = 0
    }
    updateProjectsSliderDisplay()
}

const projectsLeftArrowAction = _ => {
    const elementsByScreen = screenSize.width <= 560 ? 1 : 2
    if (projectsSliderIndex > 0) {
        projectsSliderIndex--
    } else {
        projectsSliderIndex = projectsSliderCount - elementsByScreen
    }
    updateProjectsSliderDisplay()
}

function updateProjectsSliderDisplay() {
    const slide = projectsSliderElements[0]
    const slideMarginLeft = parseInt((slide.currentStyle || window.getComputedStyle(slide)).marginRight)
    const slideWidth = slide.offsetWidth + slideMarginLeft
    projectsContainer.style.transform = `translateX(${-projectsSliderIndex * slideWidth}px)`
    projectsSliderControlPoint.style.transform = `translateX(${(projectsSliderIndex + 1) / projectsSliderCount * 100}vw)`
}